<template>
  <div class="message">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal">鏈上實時數據</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="padding: 15px 0;">
      <img src="../../assets/images/data.png" style="width: 100%;border-radius: 10px;" alt="">
    </div>
   <!-- <van-grid :column-num="3">
      <van-grid-item v-for="(value, index) in data" :key="index">
        <div style="width: 50%;text-align: center;">
          <img :src="value.icon" style="width: 40px;height: 40px;" alt="">
          <p style="font-size: 10px; color: black;margin-top: 10px;width: 60px;">{{ value.text }}</p>
        </div>
      </van-grid-item>
    </van-grid> -->
    <div class="df  aic" style="margin-top: 20px;">
      <div class="icon_box" @click="goTo('https://www.bscscan.com/token/0x1a72E23dE3942e74F166A039f3Dd893C3687693C')">
        <img src="../../assets/images/add.png" style="width: 30px;height: 30px;"/>
        <div class="fz28" style="color: black;">合約地址</div>
      </div>
      <div class="icon_box" @click="goTo('https://www.bscscan.com/token/0x1a72e23de3942e74f166a039f3dd893c3687693c?a=0xb6eB8699B24F0f701D13612cA26e4E4d296d0115')">
        <img src="../../assets/images/POS.png" style="width: 30px;height: 30px;"/>
        <div class="fz28" style="color: black;">pos礦池</div>
      </div>
      <div class="icon_box" @click="goTo('https://www.bscscan.com/token/0x1a72e23de3942e74f166a039f3dd893c3687693c?a=0x8b2A827Eb67b33341A9319Ae4c09Aa6d51EB440d')">
        <img src="../../assets/images/node.png" style="width: 30px;height: 30px;"/>
        <div class="fz28" style="color: black;">節點池</div>
      </div>
    </div>
    <div class="df  aic" style="margin-top: 20px;">
      <div class="icon_box" @click="goTo('https://www.bscscan.com/token/0x1a72e23de3942e74f166a039f3dd893c3687693c?a=0x622e28e1F6ECff8d6c704498f1E9CA7CB3af9F82')">
        <img src="../../assets/images/eoc.png" style="width: 30px;height: 30px;"/>
        <div class="fz28" style="color: black;">EOC池</div>
      </div>
      <div class="icon_box" @click="goTo('https://www.bscscan.com/token/0x1a72e23de3942e74f166a039f3dd893c3687693c?a=0x085B5F223Aa8aA205b49FeE06F8E21B3DdF7a431')">
        <img src="../../assets/images/cbc.png" style="width: 30px;height: 30px;"/>
        <div class="fz28" style="color: black;">CBC池</div>
      </div>
      <div class="icon_box" @click="goTo('https://www.bscscan.com/token/0x1a72e23de3942e74f166a039f3dd893c3687693c?a=0xD76F059D7Bbf2aa633b59eF666Ed6AFfEdC43f43')">
        <img src="../../assets/images/lp.png" style="width: 30px;height: 30px;"/>
        <div class="fz28" style="color: black;">LP池</div>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <van-row gutter="20">
        <van-col span="12">
          <div class="databox">
            <p style="font-size: 16px;color: white;">區塊高度</p>
            <p style="font-size: 18px;margin-top: 20px;color: white;">{{ list.blockNum }}</p>
          </div>
        </van-col>
        <van-col span="12">
          <div class="databox">
            <p style="font-size: 16px;color: white;">全網總銷毀</p>
            <p style="font-size: 18px;margin-top: 20px;color: white;">{{ list.total_xiaohui }}</p>
          </div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin-top: 20px;">
        <van-col span="12">
          <div class="databox">
            <p style="font-size: 16px;color: white;">我的質押</p>
            <p style="font-size: 18px;margin-top: 20px;color: white;">{{ list.my_pledge }}</p>
          </div>
        </van-col>
        <van-col span="12">
          <div class="databox">
            <p style="font-size: 16px;color: white;">我的餘額</p>
            <p style="font-size: 18px;margin-top: 20px;color: white;">{{ list.personal_money }}</p>
          </div>
        </van-col>
      </van-row>
    </div>
    <div style="margin-top: 20px;">
      <van-row style="margin-top: 20px;">
        <van-col span="12">
          <p style="font-size: 16px;color: black;">質押收益</p>
        </van-col>
        <van-col span="12" class="des">
          <p style="font-size: 16px;color: black;">{{ list.pledge_shouyi }}</p>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px;">
        <van-col span="12">
          <p style="font-size: 16px;color: black;">團隊收益</p>
        </van-col>
        <van-col span="12" class="des">
          <p style="font-size: 16px;color: black;">{{ list.team_shouyi }}</p>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px;">
        <van-col span="12">
          <p style="font-size: 16px;color: black;">推廣收益</p>
        </van-col>
        <van-col span="12" class="des">
          <p style="font-size: 16px;color: black;">{{ list.tg_shouyi }}</p>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px;">
        <van-col span="12">
          <p style="font-size: 16px;color: black;">節點收益</p>
        </van-col>
        <van-col span="12" class="des">
          <p style="font-size: 16px;color: black;">{{ list.jd_shouyi }}</p>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px;">
        <van-col span="12">
          <p style="font-size: 16px;color: black;">商城分紅</p>
        </van-col>
        <van-col span="12" class="des">
          <p style="font-size: 16px;color: black;">{{ list.shop_fh }}</p>
        </van-col>
      </van-row>
    </div>
   <!-- <div style="margin-top: 20px;">
      <van-row>
        <van-col span="24">
          <van-button type="primary" class="btnone">开始质押</van-button>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px;">
        <van-col span="24">
          <van-button type="primary" class="btntwo">马上赎回</van-button>
        </van-col>
      </van-row>

    </div> -->

  </div>
</template>
<script setup>
import { ref, reactive, watch, watchEffect } from 'vue'
import { useRouter } from "vue-router";
import { getTgData } from '@/utils/api.js'
import img1 from '../../assets/images/add.png'
import img2 from '../../assets/images/POS.png'
import img3 from '../../assets/images/node.png'
import img4 from '../../assets/images/eoc.png'
import img5 from '../../assets/images/cbc.png'
import img6 from '../../assets/images/lp.png'

const list = ref([])
getcouldData()
function getcouldData() {
  getTgData({}).then(async (res) => {
    if ((await res.code) === 200) {
      console.log(res, 'rr');
      list.value = res.data
      // detailedlist.value.create_time.forEach((v) => {
      //   time.value.push(v);
      //   });
      console.log(time.value);
    }
  });
}

 function goTo(to) {
     window.open(to);
   }

const router = useRouter();
function onClickLeft() {
  router.go(-1)
}
const data = reactive([
  {
    text: '合约地址',
    icon: img1
  },
  {
    text: 'POS矿池',
    icon: img2,
  },
  {
    text: '节点池',
    icon: img3,
  },
  {
    text: 'EOC池',
    icon: img4,
  },
  {
    text: 'CBC池',
    icon: img5,
  },
  {
    text: 'LP池',
    icon: img6,
  },
])
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__title {
  padding-top: 10px !important;
  font-size: 20px;
}
::v-deep .van-nav-bar__arrow {
      font-size: 46px !important;
      padding-top: 10px;
      color: #000;
}
::v-deep(.van-nav-bar__left) {
  font-size: 32px !important;
  font-weight: bold;
}

::v-deep .van-grid-item:nth-child(1) {
  .van-grid-item__content--center {
    align-items: flex-start;
  }
}

::v-deep .van-grid-item:nth-child(3) {
  .van-grid-item__content--center {
    align-items: flex-end;
  }
}

::v-deep .van-grid-item:nth-child(4) {
  .van-grid-item__content--center {
    align-items: flex-start;
  }
}

::v-deep .van-grid-item:nth-child(6) {
  .van-grid-item__content--center {
    align-items: flex-end;
  }
}

.btnone {
  width: 100%;
  height: 120px;
  background: #3E74EE;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  color: white;
  font-size: 28px;
  font-weight: bold;
}

.btntwo {
  width: 690px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #3E74EE;
  color: #3E74EE;
  font-size: 28px;
  font-weight: bold;
}

.databox {
  background: #3E74EE;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 30px;
}

.message {
  background: #fff;
  font-size: 14px;
  min-height: 100%;
  padding: 40px 30px;
  font-family: "pingfang" !important;


  ::v-deep(.van-nav-bar__title) {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 60px;
  }

  ::v-deep(.van-nav-bar__content) {
    height: 60px;
    margin: auto;
  }

  .pstysd {

    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
    // height: 200px;
    img {
      width: 100%;
    }
  }
}
.icon_box{

  border-radius: 10px;
  margin: auto;
  width: 20%;
  text-align: center;

}
</style>
